@charset "utf-8";

// @import "foundation/mixins/_mixins";




.mail_block {
    // padding-bottom: 100px;
    // background: #FFF;
    // padding: 30px;

    table {


        th {
            width: 30%;
            position: relative;

            @include media(tablet) {
                width: 100%;
            }
        }
    }

    #name, #kana, #tel, #mail,
    input[type="text"],
    textarea {
        border-radius: 5px;
        border: solid 1px #c0c0c0;
        background: #fbf5f3;
        // width: 80%;

        @include media(tablet) {
            width: 100%;
        }
    }

    input[type="text"], select option {
        height: auto;
    }

    .zip1 {

        li {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ._btn {
        text-align: center;

        input {
            border-radius: 40px;
            background: $green;
            border: none;
            color: #FFF;
            @include font-size(1.6);
            font-weight: bold;
            padding: 20px 40px;
            transition: all 0.2s ease-out;

            &:hover {
                @include opacity(.7);
            }
        }
    }

    .contact_tb th em {
        border-radius: 4px;
    }

    #add,#pref, #city {
        width: 80%;

        @include media(tablet) {
            width: 100%;
        }
    }
}

input[type="text"],
input[type="tel"],
input[type="email"],
textarea {
    border-radius: 5px;
    background: #faf6ed;
    border: solid 1px #CCC;
    padding: 8px;

    &[size="30"] {
        width: 30%;
    }

    &[size="40"] {
        width: 80%;

        @include media(tablet) {
            width: 100%;
        }
    }
}

input[type="radio"] {
    margin-right: 2px;
}

textarea {
    width: 100%;
    height: 200px;

    @include media(tablet) {
        width: 100%;
        height: 150px;
    }
}

._property {

    textarea {
        height: 100px;
    }
}

.select_wrap {
    // width: 40%;
    // margin-bottom: 5px;
    // margin-left: 5px;
    border: 1px solid #CCC;
    border-radius: 5px;
    background: #faf6ed url(../img/common/select_bg.jpg) right center no-repeat;
    background-size: 20px auto;
    display: inline-block;


    select {
        width: 110%;
        padding: 0 30px 0 10px;
        border: none;
        background: 0 0;
        appearance: none;
        box-shadow: none;
    }
}


.pp_box {
    background: #f7f7f7;
    margin-left: 10%;
    margin-right: 10%;
    height: 250px;
    overflow-y: scroll;
    padding: 10px 20px;
    @include font-size(1.4);

    @include media(tablet) {
        margin-left: 0;
        margin-right: 0;
    }
}

._required {
    border-radius: 4px;
    background: #dc0000;
    color: #FFF;
    @include font-size(1.2);
    display: inline-block;
    line-height: 1;
    font-weight: normal;
    padding: 3px 7px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);

    @include media(tablet) {
        right: 15px;
    }
}

.checkbox-input{
    display: none;
}
.checkbox-parts{
    padding-left: 25px;
    position:relative;
    margin-right: 25px;
}
.checkbox-parts:before{
    background: url(../img/common/icon_check2.png) 0 0 no-repeat;
    background-size: 100% auto;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    // border: 1px solid #999;
    // border-radius: 4px;
}
.mfp_checked .checkbox-parts{
    color: $green;
    font-weight: bold;
}

.check-label {
    padding-bottom: 10px;
    display: block;
}

.mfp_checked .checkbox-parts:after{
    background: url(../img/common/icon_checked.png) 0 0 no-repeat;
    background-size: 100% auto;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 18px;
    // transform: rotate(40deg);
    // border-bottom: 3px solid #d01137;
    // border-right: 3px solid #d01137;
}

input[type=radio] {
        display: none;
    }
.radio {
    cursor: pointer;
    padding-left: 26px;
    position: relative;
    display: inline-block;
    margin-right: 20px;
}
.radio:before {
    background: url(../img/common/radio_off.png) 0 0 no-repeat;
    background-size: auto 100%;
    content: '';
    display: block;
    width: 21px;
    height: 19px;
    position: absolute;
    top: 6px;
    left: 0;
    transition: all 0.2s ease-out;

    @include media(tablet) {
        top: 4px;
    }
}
.radio.mfp_checked {
    color: $orange;
    font-weight: bold;

    &:before {
        background-image: url(../img/common/radio_on.png);
    }
}