// @ fontsize
// -------------------------
// @mixin font-size($s:1.4) {
//   font-size:($s * 10) + px ;
//   font-size:$s + rem ;
// }

@mixin font-wf {
	font-family: 'Oswald', sans-serif;
}

@mixin font-wf2 {
	font-family: 'Oswald', cursive;
}

@mixin font-bold {
	font-family:"じゅん 501","Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
	font-weight: bold;
}



@mixin font-size($s:1.4) {
	@if $s == s {
		font-size: 12px;
		font-size: 1.2rem;
	} @elseif $s == m {
		font-size: 16px;
		font-size: 1.6rem;
	} @elseif $s == l {
		font-size: 20px;
		font-size: 2rem;
	}  @elseif $s == ll {
		font-size: 50px;
		font-size: 5rem;
	} @else {
		font-size:($s * 10) + px;
		font-size:$s + rem;
	}
}