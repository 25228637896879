

.frame-wrapper__video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    // margin-bottom: 50px;
}

.frame-wrapper__video iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/**
 * #Breadcrumbs
 */
.breadcrumb {
    color: #FFF;
    text-align: center;
    // background: $blue_l;  
    // color: #FFF;
    // @include font-size(1.4);
    // text-align: right;
    // padding-top: 10px;
    // padding-bottom: 100px;

    @include media(tablet) {
        // margin-bottom: 50px;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        -webkit-overflow-scrolling: touch;

        ul {
            white-space: nowrap;
            padding: 0 10px;
        }
    }

    a {
        color: #FFF;
    }

    ul {

        @include media(tablet) {
            width: auto;
        }
    }

    li {
        background: url(../img/common/arr_white.png) left 12px no-repeat;
        background-size: 14px auto;
        // @include font-size(1.7);
        padding-left: 20px;
        display: inline-block;
        margin-right: 10px;
        position: relative;

        @include media(sp) {
            background-position: left 10px;
            @include font-size(1.4);
        }
    }
}

/**
 * #btn
 */

.btn {
    text-align: center;

    a,
    button {
        // border-radius: 100px;
        background: #ff0048;
        color: #FFF;
        font-weight: bold;
        @include font-size(2);
        line-height: 1.5;
        display: inline-block;   
        min-width: 410px;
        padding: 20px 30px;
        overflow: visible;
        position: relative;
        z-index: 2;
        text-align: center;

        @include media(sp) {
            min-width: 90%;
            @include font-size(1.4);
            padding: 10px 15px;
        }

        span {
            display: inline-block;
            padding-left: 30px;
            position: relative;

            @include media(sp) {
                padding-left: 22px;
            }

            &:after {
                background: url(../img/common/arr_white.png) right center no-repeat;
                background-size: auto 100%;
                content: '';
                display: block;
                width: 24px;
                height: 6px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);

                @include media(sp) {
                    width: 18px;
                    height: 4px;
                }
            }
        }

        &:hover {

            ._icon {

                &:after {
                    right: -5px;           
                }
            }
        }
    }

    &._small {

        a {
            min-width: 220px;
            padding-top: 10px;
            padding-bottom: 10px;

            @include media(sp) {
                min-width: 100%;
            }
        }
    }


    button {
        border: none;
        cursor: pointer;
        padding: 20px 20px;
        min-width: 550px;

        @include media(tablet) {
            min-width: auto;
        }
    }
}

.win {

    // .btn .btn__icon {
    //     background-position: 0 13px;
    // }

    // .top-bnrs_btn a span {
    //     background-position: 0 8px;
    // }

    // .btn.btn__small .btn__icon {
    //     background-position: 0 8px;
    // }

    // .footerNav__list a {
    //     background-position: 0 6px;   
    // }
}

.table-1 {
    border-top: 1px solid #656565;
    // background: #FFF;
    width: 100%;
    @include font-size(1.8);

    @include media(sp) {
        @include font-size(1.6);
    }



    th,
    td {
        border-bottom: 1px solid #656565;
        padding: 15px 15px;

        @include media(tablet) {
            display: block;
            text-align: left;
        }
    }

    th {
        font-weight: bold;

        @include media(tablet) {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

.pageTtl {
    border-radius: 10px;
    background: $blue_l;
    color: #FFF;
    padding: 50px 15px;
    margin: 50px auto 100px;
    text-align: center;
}

.pageTtl__ttl {
    @include font-size(4);
    font-weight: bold;
    margin-bottom: 10px;
}

.heading__large {
    @include font-size(4);
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;

    @include media(tablet) {
        @include font-size(3);
    }

    @include media(sp) {
        @include font-size(2.5);
    }
}

.heading__medium {
    @include font-size(2.4);
    font-weight: bold;
    margin-bottom: 10px;

    @include media(tablet) {
        @include font-size(2.1);
    }

    @include media(sp) {
        @include font-size(1.9);
    }
}

.heading__small {
    @include font-size(2);
    font-weight: bold;
    margin-bottom: 5px;
}

.ttl__icon {
    margin-bottom: 5px;
    text-align: center;

    span {
        background: #5cc0d9;
        color: #FFF;
        display: inline-block;
        @include font-size(2.5);
        line-height: 1.5;
        font-weight: bold;
        position: relative;
        min-width: 195px;
        padding: 5px 5px 5px 15px;
        position: relative;

        @include media(tablet) {
            @include font-size(1.8);
        }

        @include media(sp) {
            @include font-size(1.6);
            min-width: 100px;
        }

        &:after {
            background: url(../img/common/title_icon.png) right top no-repeat;
            background-size: 100% auto;
            content: '';
            display: block;
            width: 20px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -18px;
        }
    }
}

.scroll {

    @include media(tablet) {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        -webkit-overflow-scrolling: touch;
        position: relative;
    }
}

.scroll_icon {
    display: none;

    @include media(sp) {
        border-radius: 5px;
        background: rgba(0,0,0, 0.5) url(../img/common/icon_scroll_arr.png) center 18px no-repeat;
        background-size: auto 20px;
        color: #FFF;
        @include font-size(1.3);
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
        width: 60%;
        height: 70px;
        pointer-events: none;
        padding-top: 48px;
        text-align: center;

        ._icon {
            background: url(../img/common/icon_scroll_hand.png) center center no-repeat;
            background-size: auto 100%;
            content: '';
            position: absolute;
            top: 8px;
            left: 30%;
            display: block;
            width: 30px;
            height: 34px;
            margin-left: -10px;

            animation-name: scroll_animation;
            animation-duration: 2s;
            animation-timing-function: linear;
            // animation-delay: 4.5s;
            animation-iteration-count: infinite;
            // animation-direction: reverse;
        }
    }
}

@keyframes scroll_animation {
    0 {
        left: 30%;
    }
    50% {
        left: 60%;
    }
    100% {
        left: 30%;
    }
}

/**
 * #Col
 */
.section {
    width: 1024px;
    // width: 1100px;
    // width: 1200px;
    margin-right: auto;
    margin-left: auto;

    @include media(1054) {
    // @include media(pc) {
        padding-left: 15px;
        padding-right: 15px;
        width: auto;
    }
}

.w600 {
    width: 600px;
    margin-left: auto;
    margin-right: auto;

    @include media(630) {
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
    }
}

.w800 {
    width: 800px;
    margin-left: auto;
    margin-right: auto;

    @include media(830) {
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
    }
}

.flex {
    display: flex;
    box-lines:multiple;
    flex-wrap:wrap;
    // align-items: center;
    justify-content:space-between;

    &._revease {
        flex-direction: row-reverse;

        .grid2 {
            margin-right: 0;

            &:nth-child(2n) {
                margin-right: 4%;
            }
        }
    }

    &._mb {

        > * {
            margin-bottom: 50px;
            
            @include media(tablet) {
                margin-bottom: 25px;
            
                // &:last-child {
                //     margin-bottom: 0;
                // }
            }
        }
    }

    &._mb_sp {
        > * {
            
            @include media(tablet) {

                margin-bottom: 50px;
            
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &._mb_sp-s {
        > * {
            
            @include media(tablet) {

                margin-bottom: 15px;
            
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.grid2 {
    width: 47%;
    margin-right: 6%;

    @include media(tablet) {
        width: 100%;
        margin-right: 0;
    }

    &:nth-child(2n) {
        margin-right: 0;
    } 
}

.grid3 {
    width: 30%;
    margin-right: 5%;

    @include media(tablet) {
        width: 100%;
        margin-right: 0;
    }

    &:nth-child(3n) {
        margin-right: 0;
    }   
}

.grid4 {
    width: 22%;
    margin-right: 4%;

    @include media(tablet) {
        width: 100%;
        margin-right: 0;
    }

    &:nth-child(4n) {
        margin-right: 0;
    }
}

/**
 * #Position
 */
.center {
    text-align: center;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}


/**
 * #Margin
 */
.mb-s { margin-bottom: 10px; }
.mb-m { margin-bottom: 20px; }
.mb-l { margin-bottom: 50px; }
.mb-xl { margin-bottom: 100px; }

.pb-s { padding-bottom: 10px; }
.pb-m { padding-bottom: 20px; }
.pb-l { padding-bottom: 50px; }
.pb-xl { padding-bottom: 100px; }

@include media(sp) {
    
}

/**
 * #Text
 */
.text_s {
   @include font-size(1.3);
   line-height: 1.6;
}

.text_m {
    @include font-size(1.8);
    
    @include media(tablet) {
        @include font-size(1.5);
    }
}

.text_red {
    color: #de2900;
}

.text_blue {
    color: $blue;
}

.bold {
    font-weight: bold;  
}

.tablet_left {

    @include media(tablet) {
        text-align: left;
    }
}

.tablet_center {

    @include media(tablet) {
        text-align: center;
    }
}

.sp_left {

    @include media(sp) {
        text-align: left;
    }
}

.sp_center {

    @include media(sp) {
        text-align: center;
    }
}

a.link_orange {
    color: $orange;
    text-decoration: underline;
}

/**
 * #Display
 */

.pc {
    display: block;
    
    @include media(tablet) {
        display: none;
    }
}

.tablet {
    display: none;
    
    @include media(tablet) {
        display: block;
    }
}

.sp {
    display: none;
    
    @include media(sp) {
        display: block;
    }
}

.map {
    border: none;
    width: 100%;
    height: 480px;

    @include media(tablet) {
        height: 450px;
    }
}

/*-------------------------------*/
.cvBtns {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    display: flex;
    box-lines:multiple;
    flex-wrap:wrap;
    align-items: center;
    // justify-content:space-between;

    @include media(tablet) {
        top: auto;
        bottom: 0;
        width: 100%;
        display: block;

        .contactBnt a {
            background-size: 38px auto;
            height: 60px;
            width: auto;

            @include media(sp) {
                background-size: 30px auto;
                height: 50px;
            }
        }

        .contactBnt__item {
            width: 20%;

            @include media(sp) {
                width: 33%;
            }

            &._tel {
                width: 60%;

                @include media(sp) {
                    width: 34%;
                }
            }
        }

        .contactBnt ._mail a {
            background-size: 33px auto;

            @include media(sp) {
                background-size: 28px auto;
            }
        }

        .contactBnt ._tel a {
            padding: 0;
            // padding-left: 70px;
            // background-position: 20px center;
            display: flex;
            align-items: center;
            justify-content:center;

            span {
                padding-top: 0;
                background-position: 0 center;
                padding-left: 30px;
            }

            @include media(sp) {
                display: block;
                @include text-indent();
                
                span {
                    background-size: 30px auto;
                    padding-left: 0;
                    background-position: center center;
                    display: block;
                    height: 100%;
                }
            }
        }
    }
}

.cvBtns__ttl {
    color: $orange;
    font-weight: bold;
    margin-right: 10px;
    @include font-size(2);
    line-height: 1.5;
    text-align: right;

    @include media(pc) {
        display: none;
    }

    span {
        position: relative;
        display: inline-block;
        padding: 12px 10px 12px 0;

        &:before,
        &:after {
            background: url(../img/common/baloon.png) 0 0 no-repeat;
            background-size: 100% auto;
            content: '';
            display: block;
            width: 51px;
            height: 16px;
            position: absolute;
            right: 0;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
            transform: scale(1, -1);
        }
    }
}

.contactBnt {
    justify-content: flex-start;

    &._small {

        a {
            width: 68px;
            height: 68px;
            background-size: 41px auto;
        }

        ._mail a {
            background-size: 33px auto;
        }

        ._tel {
            margin-left: 20px;

            a {
                background: url(../img/common/icon_tel_blue.png) 0 center no-repeat;
                background-size: 30px auto;
                background-color: transparent;
                color: $blue_l;
                padding-top: 0;
                display: flex;
                align-items: center;
                padding-left: 35px;

                span {
                    padding-top: 0;
                }
            }
        }
    }

    a {
        background: $green url(../img/common/icon_line.png) center center no-repeat;
        background-size: 61px auto;
        display: block;
        width: 100px;
        height: 100px;
        @include text-indent();
    }

    ._mail a {
        background-image: url(../img/common/icon_mail.png);
        background-color: $orange;
        background-size: 53px auto;
    }

    ._tel a {
        background-color: $blue_l;
        background-image: none;
        width: auto;
        text-indent: 0;
        @include font-size(3.7);
        line-height: 1;
        color: #FFF;
        padding-left: 16px;
        padding-right: 16px;

        span {
            background-image: url(../img/common/icon_tel.png);
            background-repeat: no-repeat;
            background-size: 29px auto;
            background-position: center 13px;
            display: inline-block;
            padding-top: 46px;
        }
    }
}

.cv {

    .contactBnt {

        a {
            background-color: #fff;
            width: 100%;

            @include media(tablet) {
                height: 15vw;
            }
        }

        ._line,
        ._mail {
            width: 47%;
        }

        ._line {
            margin-right: 6%;

            a {
                background-image: url(../img/common/icon_line_green.png);

                @include media(tablet) {
                    background-size: auto 10vw;
                }
            }
        }

        ._mail a {
            background-image: url(../img/common/icon_mail_orange.png);

            @include media(tablet) {
                background-size: auto 10vw;
            }
        }


        ._tel {
            width: 100%;
            margin-top: 20px;

            a {
                background-color: transparent;
                @include font-size(5.7);
                height: auto;

                @include media(sp) {
                    font-size: 12vw;
                }

                span {
                    background-position: 0 center;
                    background-size: 42px auto;
                    padding: 0;
                    padding-left: 50px;

                    @include media(sp) {
                        background-size: 9vw;
                        padding-left: 9.5vw;
                    }
                }
            }
        }
    }
}

.eng {
    font-family: 'Oswald', sans-serif;
}

.listIcon {

    li {
        position: relative;
        line-height: 1.5;
        padding-left: 40px;
        margin-bottom: 5px;

        @include media(tablet) {
            padding-left: 28px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:after {
            background: url(../img/common/icon_check.png) 0 0 no-repeat;
            background-size: 100% auto;
            content: '';
            display: block;
            width: 33px;
            height: 33px;
            position: absolute;
            top: 6px;
            left: 0;

            @include media(tablet) {
                width: 22px;
                height: 22px;
            }
        }
    }
}

.bg1 {
    background: #fff5e1;
    padding-top: 100px;
    padding-bottom: 100px;
}

.bg2 {
    background: #d3f1f8;
    padding-top: 100px;
    padding-bottom: 100px;
}

.cv {
    margin-bottom: 100px;
    overflow: hidden;

    .flex {
        max-width: 1060px;
        width: 100%;
        color: #FFF;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        // align-items: center;
        justify-content: center;

        @include media(tablet) {
            justify-content: space-between;
        }
    }
}

.cv__left {
    background: $blue;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 50%;
    // width: 430px;
    padding-right: 100px;
    position: relative;

    @include media(tablet) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    &:after {
        background: $blue;
        content: '';
        display: block;
        width: 1000px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -999px;
    }

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 82px 0 82px 50px;
        border-color: transparent transparent transparent $blue;
        display: block;
        position: absolute;
        top: 50%;
        right: -48px;
        z-index: 2;
        transform: translate(0, -50%);

        @include media(tablet) {
            top: auto;
            bottom: -100px;
            right: 50%;
        transform: translate(50%, 0) rotate(90deg);
        }
    }
}

.cv__right {
    background: $orange;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 50%;
    // width: 430px;
    padding-left: 100px;
    position: relative;

    @include media(tablet) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 80px;
    }

    &:after {
        background: $orange;
        content: '';
        display: block;
        width: 1000px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -999px;
    }
}

.cv__ttl {
    @include font-size(3);
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 10px;

    @include media(tablet) {
        @include font-size(2.5);
    }

    @include media(sp) {
        @include font-size(2);
    }
}