/*
* #Header
*/

.headerInner {
    padding: 30px 20px;
}

.headerLogo__logo {

    a {
        display: flex;
        box-lines:multiple;
        flex-wrap:wrap;
        align-items: center;
        // justify-content:space-between;

        @include media(1350) {
            display: block;
        }

        @include media(tablet) {
            text-align: center;
        }
    }

    img {
        max-width: 286px;
        width: 100%;
        display: inline-block;
        margin-right: 18px;

        @include media(1350) {
            display: block;
            margin-right: 0;
        }

        @include media(tablet) {
            display: inline-block;
            max-width: 50%;
        }
    }

    ._txt {
        display: inline-block;

        @include media(1350) {
            display: block;
            margin-top: 10px;
            @include font-size(1.4);
        }
    }
}


.headerInner {
    
}

.headerLogo {
    
}



#menu_popup {
    border-radius: 5px;
    background: rgba(0,0,0,.8);
    line-height: 1.4;
    color: #FFF;
    position: fixed;
    top: 60px;
    right: 10px;
    padding: 5px 10px;
    z-index: 103;
    @include font-size(1.2);

    span {
        position: relative;

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6px 12px 6px;
            border-color: transparent transparent rgba(0,0,0,.8) transparent;
            display: block;
            position: absolute;
            top: -15px;
            right: 6px;
        }
    }

    &.none {
        display: none;
    }
}

@include media(1100, min) {
    
}


// @include media(1100) {

//     .headerNav__list {

//         > li {
//             border-bottom: 1px dotted $line;

//             &> a {
//                 background: url(../img/common/arr_orange.png) 15px center no-repeat;
//                 background-size: 22px auto;
//                 padding: 15px 15px 15px 50px;
//             }

//             .eng {
//                 color: $orange;
//                 float: right;
//                 @include font-size(1.4);
//             }
//         }

//         a {
//             display: block;
//         }

//         .children {


//             li {
//                 border-top: 1px dotted $line;
//             }

//             a {
//                 padding: 6px 15px;
//             }
//         }
//     }

//     .drawer-nav {
//         // border-radius: 30px;
//         // background-image: url(../img/common/bg2.jpg);
//         // // height: auto;
//         // height: calc(100% - 40px);
//         // width: 400px;
//         // display: flex;
//         // align-items: center;
//         // justify-content: center;

//         // @include media(sp) {
//         //     width: calc(100% - 40px)!important;
//         // }
//     }

//     .drawer--right .drawer-nav {
//         // right: calc(-100% - 40px);
//     }

//     .gnav {
//         background: url(../img/common/line2.png) 0 bottom repeat-x;
//         background-size: auto 6px;
//         // margin-bottom: 20px;
//     }

//     .gnavList__item {
//         // background: url(../img/common/line2.png) 0 0 repeat-x;
//         // background-size: auto 6px;

//         a {
//             display: block;
//             // color: #FFF;
//             @include font-size(1.8);
//             font-weight: bold;
//             line-height: 1.5;
//             padding: 20px 20px 20px 25px;
//             position: relative;
//             // text-align: center;

//             &:after {
//                 background: $red;
//                 content: '';
//                 display: block;
//                 width: 15px;
//                 height: 4px;
//                 position: absolute;
//                 top: 50%;
//                 left: 0;
//                 margin-top: -2px;
//             }
//         }
//     }

//     .sub_nav {

//     }

//     .gnav_item {
         
//     }

//     .gnav_sub_list {
       
//     }
    
//     .drawer-hamburger {
//         display: inline-block;
//         text-align: center;
//         // border-radius: 50%;
//         color: #FFF;
//         @include font-size(1);
//         background: #c2590e;
//         width: 60px;
//         height: 60px;
//         padding: 15px 15px 10px;
//         box-sizing: border-box;
//         top: auto;
//         bottom: 0;
//     }

//     .drawer-hamburger-icon {
//         margin-top: 5px;
//         margin-bottom: 10px;
//     }

//     .drawer-hamburger-icon, .drawer-hamburger-icon:before, .drawer-hamburger-icon:after {
//         height: 2px;
//     }

//     .drawer-hamburger:hover {
//         background: #c2590e;


//     }

//     .drawer-overlay {
//         z-index: 9;
//     }

//     .drawer-hamburger-icon,
//     .drawer-hamburger-icon:before,
//     .drawer-hamburger-icon:after {
//         background: #FFF;
//     }

//     .drawer-hamburger-icon:after {
//         top: 6px;
//     }

//     .drawer-hamburger-icon:before {
//         top: -6px;
//     }

//     .drawer-overlay {
//         background: rgba(0, 0, 0, .5);
//     }

//     .drawer-open {
//         // .drawer-hamburger-icon,
//         .drawer-hamburger-icon:before,
//         .drawer-hamburger-icon:after {
//             // background: $green;
//         }
//     }
    
//     .drawer--right.drawer-open .drawer-nav {
//         // right: 20px;
//         // top: 20px;
//     }
// }

// #spNavBtn {
//     display: none;

//     @include media(1100) {
//         display: block;
//     }
// }


/**
 * #Footer
 */


footer {
    
}


.footerInner {
    position: relative;

    @include media(1000) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.footerInner__flex {
    align-items: center;

    @include media(1000) {
        display: block;
    }
}

.footerDate {
    width: 32%;
    padding-left: 3%;

    @include media(1000) {
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
        margin-bottom: 30px;
    }

    @include media(tablet) {
        padding-left: 15%;
        padding-right: 15%;
    }

    @include media(sp) {
        padding-left: 0;
        padding-right: 0;
    }

    .telnum a {

        @include media(tablet) {
            font-size: 4.7vw;
        }

        @include media(sp) {
            font-size: 7.7vw;
        }
    }

    .headerAccess__time {

        @include media(tablet) {
            font-size: 2vw;

            dt {
                width: calc(100% - 10.5vw);
            }

            dd {
                width: 10vw;
            }
        }

        @include media(sp) {
            font-size: 3.5vw;

            dt {
                width: calc(100% - 16.5vw);
            }

            dd {
                width: 16vw;
            }
        }
    }
}


.footerAccess {
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    width: 65%;
    display: flex;
    box-lines:multiple;
    flex-wrap:wrap;
    // align-items: center;
    justify-content:space-between;

    @include media(1000) {
        width: 100%;
        border-radius: 0;
    }
}

.footerAccess__map {
    width: 65%;

    @include media(tablet) {
        width: 100%;
    }

    ._map {
        width: 100%;
        height: 100%;
        vertical-align: top;

        @include media(tablet) {
            height: 300px;
        }
    }
}

.footerAccess__park {
    width: 35%;

    @include media(tablet) {
        width: 100%;
        display: flex;
        box-lines:multiple;
        flex-wrap:wrap;
        // align-items: center;
        justify-content:space-between;

        >p {
            width: 50%;
        }
    }

    @include media(sp) {

        > p {
           width: 100%; 
        }
    }
}

.footerDate__add {
    text-align: center;

    span {
        display: inline-block;
        position: relative;
        padding-left: 22px;

        &:after {
            background: url(../img/common/icon_pin.png) center center no-repeat;
            background-size: 100% auto;
            content: '';
            display: block;
            width: 17px;
            height: 22px;
            position: absolute;
            top: 0px;
            left: 0;
        }
    }
}

.footerDate__time {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
}

.copyright {
    padding-bottom: 30px;
    text-align: center;
}

.sp-fixNav {
    display: none;

    @include media(sp) {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: calc(100% - 60px);

        ul {
            display: flex;
            box-lines:multiple;
            flex-wrap:wrap;
            // align-items: center;
            justify-content:space-between;
        }

        li {
            border-right: 1px solid #FFF;
            width: 25%;
        }

        a {
            background: $orange;
            color: #FFF;
            font-size: 3vw;;
            display: block;
            text-align: center;
            height: 60px;
            padding-top: 40px;
        }

        ._access {

            a {
                background: $orange url(../img/common/icon_map.png) center 6px no-repeat;
                background-size: auto 30px;
            }
        }

        ._park {

            a {
                background: $orange url(../img/common/icon_park2.png) center 6px no-repeat;
                background-size: auto 30px;
            }
        }

        ._tel {

            a {
                background: $orange url(../img/common/icon_tel_white.png) center 6px no-repeat;
                background-size: auto 30px;
            }
        }

        ._time {

            a {
                background: $orange url(../img/common/icon_time_white.png) center 6px no-repeat;
                background-size: auto 30px;
            }
        }
    }
}

// .sp-fixline__btn {
//     display: none;

//     @include media(1300) {
//         display: block;
//         position: fixed;
//         bottom: 0;
//         left: 0;
//         width: calc(100% - 55px);
//         z-index: 8;

//         a {
//             display: block;
//             background: $line;
//             color: #FFF;
//             @include font-size(2);
//             font-weight: bold;
//             height: 50px;
//             padding-top: 8px;
//             text-align: center;

//             span {
//                 position: relative;
//                 padding-left: 50px;

//                 &:after {
//                     background: url(../img/common/logo_line@2x.png) 0 center no-repeat;
//                     background-size: 100% auto;
//                     content: '';
//                     display: block;
//                     width: 40px;
//                     height: 50px;
//                     position: absolute;
//                     top: 50%;
//                     left: 0;
//                     transform: translate(0, -50%);
//                 }
//             }
//         }
//     }
// }

#pageTop {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 10;
    transform: translate(0, -110%);

    // @include media(1300) {
    //     bottom: 70px;
    // }

    a {
        background: $orange;
        border-radius: 50%;
        color: #FFF;
        @include font-size(1.6);
        // border-radius: 10px;
        display: block;
        width: 86px;
        height: 86px;
        // @include text-indent;
        // transform: rotate(-90deg);
        position: relative;
        padding-top: 50px;
        text-align: center;

        @include media(sp) {
            width: 50px;
            height: 50px;
            @include font-size(1.2);
            padding-top: 27px;
        }


        &:after {
            background:  url(../img/common/arr_white.png) center center no-repeat;
            background-size: 100% auto;
            content: '';
            display: block;
            width: 34px;
            height: 8px;
            position: absolute;
            top: 8px;
            left: 28%;
            transform: rotate(-90deg) translate(-50%, 0);

            @include media(sp) {
                top: 3px;
                width: 20px;
                height: 7px;
            }
        }
    }
}



/**
 * #Main
 */

#container {
    @include opacity(0);
    // padding-top: 70px;
    overflow: hidden;

    &.vis {
        @include opacity(1);
    }

    @include media(tablet) {
        padding-bottom: 80px;
    }
}

#contents {
    // padding-top: 100px;
    // padding-bottom: 150px;

    // @include media(1100) {
    //     padding-top: 0;
    // }

    // @include media(tablet) {
    //     padding-bottom: 75px;
    // }

    // @include media(1300) {
    //     padding-top: 80px;
    // }

    // @include media(sp) {
    //     padding-top: 60px;
    // }
}


