@charset "utf-8";

.top-fv {
	padding-top: 50px;
	overflow: hidden;

	@include font-size(3);
	font-weight: bold;

	@include media(tablet) {
		@include font-size(2);
		padding-top: 0;
	}

	.section {
		width: 1200px;
		position: relative;
		padding-top: 30px;
		padding-bottom: 100px;

		@include media(1230) {
			width: auto;
			padding-left: 15px;
			padding-right: 15px;
		}
	}


	.listIcon {
		position: relative;
		z-index: 2;
	}
}

.top-fv__free {
	position: absolute;
	z-index: 2;
	top: 326px;
    left: 510px;

    @include media(1230) {
		top: 27vw;
    	left: 43vw;
    }

    @include media(tablet) {
    	top: 44vw;
    	left: 79vw;
    }

    @include media(sp) {
    	top: 82vw;
    	left: 72vw;
    }

	img {
		max-width: 180px;
		width: 100%;

		@include media(1230) {
			max-width: 15vw;
		}

		@include media(sp) {
			max-width: 23vw;
		}
	}
}

.top-fv__ttl {
	position: relative;
	z-index: 2;
	margin-bottom: 50px;

	@include media(tablet) {
		text-align: center;
		margin-bottom: 0;
	}

	img {
		max-width: 495px;
		width: 100%;

		@include media(1230) {
			max-width: 42%;
		}

		@include media(tablet) {
			max-width: 60%;
		}

		@include media(sp) {
			max-width: 80%;
		}
	}
}

.top-fv__txt {
	position: relative;
	z-index: 3;
}

.top-fv__bg {
	position: absolute;
	top: 0;
	right: -415px;
	z-index: 1;

	@include media(1230) {
		right: -22vw;
	}

	@include media(tablet) {
		position: static;
		right: 0;
		margin-top: -4vw;
	}

	img {
		width: 1162px;

		@include media(1230) {
			width: 82vw;
		}

		@include media(tablet) {
			width: 100vw;
		}
	}
}

.top-fv__human {
	position: absolute;
	bottom: 0;
	right: -188px;
	z-index: 3;

	@include media(1230) {
		right: 0;
		text-align: right;

		img {
			max-width: 45%;
		}
	}

	img {
		max-width: 687px;
		width: 100%;
	}
}

.top-flow {

	._line1 {
		position: relative;

		@include media(tablet) {
			margin-bottom: 20px;
		}

		&:after {
			background: $orange;
			content: '';
			display: block;
			width: 100px;
			height: 20px;
			position: absolute;
			top: 90px;
			right: 3%;
			transform: translate(100%, 0);
			z-index: 1;

			@include media(tablet) {
				width: 20px;
				height: 50px;
				top: auto;
				bottom: 0;
				right: 50%;
				transform: translate(-50%, 50%);
			}
		}
	}

	._line2 {
		position: relative;

		&:after {
			background: $orange;
			content: '';
			display: block;
			width: 20px;
			height: 80%;
			position: absolute;
			top: 50%;
			right: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
		}
	}
}

.top-flow__box {
	border-radius: 10px;
	background: #FFF;
	padding: 20px;
	position: relative;
	z-index: 2;

	&._flow1 {
		height: 100%;
	}

	&._flow2,
	&._flow3 {
		margin-bottom: 20px;
	}
}

.top-flow__ttl {
	@include font-size(3);
	font-weight: bold;
	line-height: 1.3;
	padding-left: 55px;
	position: relative;
	margin-bottom: 10px;

	@include media(tablet) {
		@include font-size(2.5);
		padding-left: 46px;
	}

	@include media(sp) {
		@include font-size(2.2);
		padding-left: 36px;
	}

	._num {
		border-radius: 50%;
		background: #619be1;
		color: #FFF;
		@include font-wf;
		@include font-size(2.6);
		display: block;
		width: 46px;
		height: 46px;
		position: absolute;
		top: -4px;
		left: 0;
		display: flex;
	    box-lines:multiple;
	    flex-wrap:wrap;
	    align-items: center;
	    justify-content:center;

	    @include media(tablet) {
	    	@include font-size(2);
	    	width: 37px;
	    	height: 37px;
	    }

	    @include media(tablet) {
	    	width: 30px;
	    	height: 30px;
	    	top: -1px;
	    }
	}
}

.link__box {

	&.grid2 {

		@include media(tablet) {
			width: 47%;
		}
	}

	&:nth-child(1),
	&:nth-child(2) {
		margin-bottom: 20px;
	}

	a {
		border: 1px solid #dadada;
		display: block;
		position: relative;

		&:after {
			background: url(../img/common/icon_blank.png) 0 0 no-repeat;
			background-size: 100% auto;
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			position: absolute;
			bottom: 5px;
			right: 5px;
		}
	}
}

.top-otokuBox {
	border-radius: 10px;
	background: #d3f1f8;
	padding: 20px;
	text-align: center;

	@include media(tablet) {
		// margin-left: 20%!important;
		// margin-right: 20%!important;
		// margin-bottom: 15px;
		width: 32%;
    	margin-right: 2%;

    	&:nth-child(3n) {
    		margin-right: 0;
    	}
	}

	// @include media(sp) {
	// 	margin-left: 10%!important;
	// 	margin-right: 10%!important;
	// }
}

.top-otoku__home {

	img {
		height: 217px;

		@include media(tablet) {
			height: 20vw;
		}
	}
}

.top-otoku__ttl {
	margin-bottom: 5px;

	span {
		border-radius: 50px;
		background: #5cc0d9;
		display: inline-block;
		color: #FFF;
		@include font-size(2.5);
		line-height: 1.5;
		font-weight: bold;
		padding-left: 15px;
		padding-right: 15px;
		min-width: 90%;

		@include media(tablet) {
			@include font-size(1.8);
		}
	}
}

.top-otoku__price1 {
	background: url(../img/common/baloon2.png) center bottom no-repeat;
	background-size: auto 12px;
	color: #5cc0d9;
	padding-bottom: 20px;
	@include font-size(2.5);
	line-height: 1;
	font-weight: bold;

	@include media(tablet) {
		@include font-size(2);
		background-size: auto 8px;
		padding-bottom: 13px;
	}

	span {
		@include font-size(3.7);
		font-weight: normal;

		@include media(tablet) {
			@include font-size(2.5);
		}
	}
}

.top-otoku__flex {

	@include media(sp) {
		width: 630px;
	}
}

.top-otoku__txt {
	@include font-size(2);
	font-weight: bold;

	@include media(tablet) {
		@include font-size(1.8);
	}
}

.top-otoku__price2 {
	@include font-size(3.7);
	font-weight: bold;
	line-height: 1;
	position: relative;

	@include media(tablet) {
		@include font-size(2.8);
	}

	span {
		@include font-size(4.9);
		color: $red;
		font-weight: normal;

		@include media(tablet) {
			@include font-size(3.5);
		}
	}

	&:after {
		background: url(../img/top/icon_cross.png) center center no-repeat;
		background-size: auto 100%;
		content: '';
		display: block;
		width: 190px;
		height: 40px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;

		@include media(tablet) {
			height: 30px;
		}
	}

	._free {
		position: absolute;
		right: -20px;
		bottom: 0;
		width: 83px;
		height: 83px;
		z-index: 2;

		@include media(tablet) {
			width: 59px;
			height: 59px;
		}
	}
}

.top-otoku__main {
	@include font-size(4.3);
	font-weight: bold;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;

	@include media(tablet) {
		@include font-size(3.5);
	}

	@include media(sp) {
		@include font-size(2.5);
	}

	span {
		@include font-size(5.3);

		@include media(tablet) {
			@include font-size(4);
		}

		@include media(sp) {
			@include font-size(3);
		}
	}
}

.top-otokuUita {
	align-items: center;
    justify-content: center;
    // flex-wrap:nowrap;

    @include media(pc) {
    	display: block;
    }
}

.top-otokuUita__ttl {
	border-radius: 50%;
	background: #5cc0d9;
	color: #FFF;
	@include font-size(2.9);
	font-weight: bold;
	display: flex;
	align-items: center;
    justify-content: center;
    width: 162px;
    height: 162px;
    margin-right: 56px;
    position: relative;

    // @include media(pc) {
    // 	@include font-size(2.5);
    // 	width: 110px;
    // 	height: 110px;
    // }

    @include media(pc) {
    	margin-bottom: 30px;
    	margin-right: 0;
    	// width: 130px;
    	// height: 130px;
    	margin-left: auto;
    	margin-right: auto;
    	// @include font-size(2.5);
    }

    &:after {
    	content: '';
    	width: 0;
		height: 0;
		border-style: solid;
		border-width: 12.5px 0 12.5px 20px;
		border-color: transparent transparent transparent #5cc0d9;
		display: block;
		position: absolute;
		top: 50%;
		right: -17px;
		transform: translate(0, -50%);

		@include media(pc) {
			border-width: 20px 12.5px 0 12.5px;
			border-color: #5cc0d9 transparent transparent transparent;
			top: auto;
			bottom: -17px;
			right: 50%;
			transform: translate(50%, 0);
		}
    }
}

.top-otokuUita__list {
	@include font-size(2.5);
	font-weight: bold;
	display: flex;
    box-lines:multiple;
    flex-wrap:wrap;
    // align-items: center;
    // justify-content:space-between;

    @include media(pc) {
    	justify-content:center;
    }

    @include media(tablet) {
    	display: block;
    }

    // @include media(tablet) {
    // 	@include font-size(1.8);

    // 	ul {
    // 		margin-bottom: 5px;
    // 		margin-right: 0;
    // 	}
    // }

    .listIcon:first-child {
    	margin-right: 50px;

    	@include media(tablet) {
    		margin-right: 0;
    	}
    }
}

.top-voice {
	background: url(../img/top/faq_bg.jpg) center center no-repeat;
	background-size: cover;
	padding-top: 100px;
	padding-bottom: 100px;
}

.top-voiceBox {
	border: 2px solid $blue;
	border-radius: 10px;
	padding: 20px;
	background: #FFF;
	margin-left: 10px;
	margin-right: 10px;
	width: 380px;

	@include media(sp) {
		width: 280px;
	}

	&:nth-child(2n) {
		margin-top: 40px;

		@include media(sp) {
			margin-top: 0;
		}
	}
}

.top-voice__ttl {
	@include font-size(2);
	font-weight: bold;
	margin-bottom: 10px;

	@include media(sp) {
		@include font-size(1.8);
	}
}

.top-news__ttl {
	padding-top: 10px;
}

.top-profile__icon {
	display: block;
	margin-bottom: 5px;
	text-align: center;

	img {
		width: 117px;
	}
}

.top-profileBox {
	border-radius: 20px;
	background: #FFF;
	padding: 60px 30px 30px;
	position: relative;

	@include media(tablet) {
		padding: 9vw 15px 15px;
	}

	.flex {

		@include media(tablet) {
			display: block;
		}
	}
}

.top-profileBox__ttl {
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(0, -50%);
	width: 100%;
	text-align: center;

	img {
		// max-width: 270px;
		// width: 100%;
		height: 60px;

		@include media(sp) {
			// max-width: 50%;
			height: 40px;
		}
	}
}

.top-profileBox__txt {
	width: 65%;

	@include media(tablet) {
		width: auto;
		margin-bottom: 30px;
	}
}

.top-profileBox__img {
	width: 29%;

	@include media(tablet) {
		width: auto;
	}
}

.top-profileBox__baloon {
	border-radius: 10px;
	background: #fff5e1;
	padding: 20px;
	margin-top: 50px;
	position: relative;
	@include font-size(1.4);

	&:after {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 15px 30px 15px;
		border-color: transparent transparent #fff5e1 transparent;
		position: absolute;
		top: -20px;
		left: 50%;
		transform: translate(-50%, 0);
	}
}

.top-profileBox__chara {

	@include media(tablet) {
		text-align: center;

		img {
			width: 50%;
		}
	}
}


.faqBox {
	margin-bottom: 50px;
}

.faqBox__ttl {
	@include font-size(2.4);
	font-weight: bold;
	margin-bottom: 10px;
	padding-left: 60px;
	position: relative;

	@include media(tablet) {
        @include font-size(2.1);
        padding-left: 50px;
    }

    @include media(sp) {
        @include font-size(1.9);
        padding-left: 45px;
    }

	&:after {
		background: url(../img/top/faq_icon.png) 0 0 no-repeat;
		background-size: 100% auto;
		content: '';
		display: block;
		width: 53px;
		height: 53px;
		position: absolute;
		top: -2px;
		left: 0;

		@include media(tablet) {
			width: 44px;
		}

		@include media(sp) {
			width: 40px;
		}
	}
}

.faqBox__txt {
	padding-left: 60px;

	@include media(tablet) {
        padding-left: 50px;
    }

    @include media(sp) {
        padding-left: 45px;
    }
}

.top-company {
	background: #878787;
	padding-top: 100px;
	padding-bottom: 100px;
	color: #FFF;
}

.table-2 {
	border-top: 1px solid #ababab;
	@include font-size(1.8);
	width: 100%;

	@include media(sp) {
		@include font-size(1.5);
	}

	th,
	td {
		border-bottom: 1px solid #ababab;
		padding: 10px;
	}

	th {
		font-weight: bold;
		text-align: right;
	}
}

.top-news__item {

	@include media(tablet) {
		width: 47%;
		margin-right: 6%;
		margin-bottom: 30px;

		&:nth-child(2n) {
			margin-right: 0;
		}
	}
}

.top-voice__chara {
	display: inline-block;
	position: relative;
	padding-left: 200px;
	padding-right: 200px;

	@include media(sp) {
		padding-left: 100px;
		padding-right: 100px;
	}

	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 130px;
		height: 148px;
		bottom: 0;

		@include media(sp) {
			width: 80px;
			bottom: -10vw;
		}
	}

	&:before {
		background: url(../img/top/faq_chara1.png) 0 0 no-repeat;
		background-size: 100% auto;
		left: 0;
	}

	&:after {
		background: url(../img/top/faq_chara2.png) 0 0 no-repeat;
		background-size: 100% auto;
		right: 0;
	}
}