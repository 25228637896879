@charset "utf-8";

// @import "foundation/mixins/_mixins";



.postMain {
	width: 73%;

	@include media(tablet) {
		width: 100%;
		margin-bottom: 50px;
	}
}

.wp-side {
	// float: left;
	width: 24%;
	margin-right: 4%;

	@include media(tablet) {
		width: 100%;
		margin-right: 0;
		float: none;
	}

	.heading__medium {
		@include font-size(1.7);
	}

	.heading__medium:after {
		top: 18px;
	}
}

.post_wrap {
	// display: flex;
	// box-lines:multiple;
	// flex-wrap:wrap;
	// align-items: center;
	// justify-content:space-between;
	flex-direction: row-reverse;

	.heading__large {
		text-align: left;

		span {
			display: block;
		}
	}
}

.post_main {
	// float: right;
	width: 72%;

	@include media(tablet) {
		float: none;
		width: 100%;
		margin-bottom: 100px;
	}

	.heading_medium {
		background-position: 0 0;
		text-align: left;

		@include media(tablet) {
			margin-bottom: 10px;
			@include font-size(1.6);
			padding-bottom: 5px;
			padding-top: 8px;
		}

		&:after {
			left: 0;
		    transform: translate(0,0);
		}

		a {
			color: $blue;
		}
	}
}

.wp-postItem {
	border-bottom: 2px dotted #959593;
	padding-bottom: 50px;
	margin-bottom: 50px;
	position: relative;

	@include media(tablet) {
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	.heading__large {
		margin-bottom: 15px;
	}

	.flex {
		align-items: center;

		@include media(sp) {
			align-items: flex-start;
		}
	}
}

.postListBox__ttl {
	background: url(../img/common/line1.png) center bottom 0 repeat-x;
    background-size: 14px auto;
	@include font-size(3);
	line-height: 1.5;
	font-weight: bold;
	padding-bottom: 10px;
	margin-bottom: 20px;

	@include media(sp) {
		@include font-size(2.5);
	}
}

.wp-postItem__img {
	width: 24%;
	margin-right: 3%;
}

.wp-postItem__txt {
	width: 73%;

	// @include media(tablet) {
	// 	// width: 62%;
	// 	// @include font-size(1.2);
	// 	// display: none;
	// }
}

.wp-postItem__btn {
	position: absolute;
	bottom: 0;
	right: 0;

	@include media(tablet) {
		position: static;
		text-align: right;
		margin-top: 10px;
		@include font-size(1.1);
		margin-right: 20px;
	}

	span {
		background: $orange;
		color: #FFF;
		font-weight: bold;
		line-height: 1.4;
		display: inline-block;
		padding: 3px 20px 5px;
		min-width: 130px;
		position: relative;

		@include media(tablet) {
			min-width: auto;
		}

		&:after {
            background: url(../img/common/arr_blue.png) 0 center no-repeat;
            background-size: 100% auto;
            content: '';
            display: block;
            width: 40px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, 0);
            transition: all 0.2s ease-out;

            @include media(tablet) {
                width: 25px;
            }
        }

        // &:hover {

        //     &:after {
        //         transform: translate(60%, 0);
        //     }
        // }
	}
}

.wp-postItem__ttl {
	color: $blue;
	font-weight: bold;
	@include font-size(2.5);
	line-height: 1.5;
	margin-bottom: 6px;

	@include media(tablet) {
		@include font-size(1.8);
		line-height: 1.5;
	}

	@include media(sp) {
		@include font-size(1.6);
	}
}

.wp-postSingle__ttl {
	color: $orange;
	@include font-size(3);
	font-weight: bold;
	line-height: 1.5;
	margin-bottom: 12px;

	@include media(tablet) {
		@include font-size(2.5);
	}

	@include media(sp) {
		@include font-size(2);
	}
}

.wp-postItem__link {
	// display: block;

}

.wp-postItem__times {
	display: flex;
	box-lines:multiple;
	flex-wrap:wrap;
	// align-items: center;
	// justify-content:space-between;
	// margin-bottom: 5px;

	@include media(sp) {
		@include font-size(1.4);
	}

	&._single {
		margin-bottom: 50px;
	}
}

.wp-postItem__date {

	.categoryList__item {
		// border-radius: 20px;
		// background: $blue_l;
		// line-height: 1;
		display: inline-block;
		// vertical-align: top;
		// padding: 4px 15px 7px;
		// margin-right: 10px;
		// margin-bottom: 10px;
	}
}

.wp-postItem__desc {

	@include media(tablet) {
		display: none;
	}
}

.categoryList__item {

	span {
		border-radius: 5px;
		background: $orange;
		display: inline-block;
		color: #FFF;
		@include font-size(1.4);
		line-height: 1.5;
		padding: 1px 10px;
		vertical-align: 0;
		min-width: 100px;
		text-align: center;
		margin-right: 10px;
		margin-bottom: 8px;
	}
}

.wp-postItem__time {
	margin-right: 10px;
	display: inline-block;
	vertical-align: top;

	// @include media(tablet) {
	// 	@include font-size(1.2);
	// 	margin-right: 10px;
	// }
}

.post-categories {
	display: inline-block;
}

.post-categorie {
	border-radius: 5px;
	background: $blue;
	display: inline;
	color: #FFF;
	@include font-size(1.4);
	padding: 4px 10px;
	min-width: 100px;
	margin-right: 10px;
	vertical-align: 3px;
}

// .sideBox__wrap {

// 	@include media(tablet) {
// 		padding-left: 15px;
// 		padding-right: 15px;
// 	}

	
// }

.sideBox {
	padding-bottom: 50px;
}

.sideBox__ttl {
	background: $blue;
	color: #FFF;
	@include font-size(2);
	font-weight: bold;
	line-height: 1.5;
	padding: 10px 20px;
	margin-bottom: 10px;
}

.sideBox__list {
	line-height: 1.5;
	

	> li {
		margin-bottom: 10px;

		@include media(tablet) {
			border-bottom: 1px dotted $line;
			margin-bottom: 0;
		}

		>a {
	        padding-left: 18px;
	        display: block;
	        position: relative;

	        &:after {
	        	position: absolute;
			    top: 8px;
			    left: 0;
			    display: block;
			    width: 14px;
			    height: 6px;
			    content: '';
			    background: url(../img/common/arr_blue.png) right center no-repeat;
			    background-size: auto 100%;

			    @include media(tablet) {
			    	top: 22px;
			    	left: 9px;
			    }
	        }

	        @include media(tablet) {
	        	background-position: 0 23px;
	        	padding-top: 15px;
	        	padding-bottom: 15px;
	        	padding-left: 30px;
	        }
		}
	}

	.children {
		padding-left: 20px;

		li {
			padding-left: 10px;
			position: relative;
			margin-top: 5px;

			@include media(tablet) {
				// border-top: 1px dotted $line;
				margin-top: 0;
			}

			&:after {
				border-radius: 50%;
				background: $orange;
				content: '';
				color: $orange;
				display: block;
				width: 4px;
				height: 4px;
				position: absolute;
				top: 10px;
				left: 0;

				@include media(tablet) {
					top: 16px;
				}
			}


			a {
				display: block;

				@include media(tablet) {
					padding-top: 8px;
					padding-bottom: 8px;
				}
			}
		}
	}
}

.postBody {
	h2 {
        clear: both;
        background: $orange;
        font-weight: bold;
        color: #FFF;
	    @include font-size(2.4);
	    font-weight: bold;
	    padding: 10px 15px;
	    margin-top: 40px;
	    position: relative;

	    @include media(tablet) {
	    	@include font-size(2);
	    }
    }

    h3 {
        clear: both;
        border-top: 2px solid $orange;
	    border-bottom: 2px solid $orange;
	    color: $orange;
	    @include font-size(2.1);
	    font-weight: bold;
	    padding-top: 10px;
	    padding-bottom: 10px;
	    margin-top: 40px;

	    @include media(tablet) {
	        @include font-size(1.8);
	    }
    }

    h4 {
        clear: both;
        background: #f2e8ce;
	    // color: $orange;
	    // border-bottom: 1px solid $orange;
	    @include font-size(2);
	    font-weight: bold;
	    padding: 14px 20px;
		margin-top: 40px;
    }

    h5 {
    	border-bottom: 1px solid $orange;
    	clear: both;
	    @include font-size(2);
	    font-weight: bold;
	    margin-top: 40px;
	    padding-bottom: 5px;
    }

    h6 {
        clear: both;
    	@include font-size(1.8);
	    font-weight: bold;
	    margin-top: 40px;
	    position: relative;
	    padding-left: 16px;

	    &:after {
	    	background: $orange;
	    	content: '';
	    	display: block;
	    	width: 7px;
	    	height: 23px;
	    	position: absolute;
	    	top: 2px;
	    	left: 0;
	    }
    }

    p {
        clear: both;
        margin-top: 10px;
    }

    ul {
    	margin-top: 20px;

    	li {
    		font-weight: bold;
    		margin-bottom: 5px;
    		position: relative;
    		padding-left: 18px;

    		&:before {
		        // background: $dark_b;
		        content: '';
		        display: block;
		        width: 12px;
		        height: 4px;
		        position: absolute;
		        top: 12px;
		        left: 0;

		        @include media(tablet) {
		            top: 10px;
		        }
		    }
    	}
    }

    img,
    video,
    iframe {
        max-width: auto;
        height: auto;
    }

    strong {
    	font-weight: bold;
    }

    .alignleft {
        float: left;
        margin: 0.5em 1em 0.5em 0;
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .alignright {
        float: right;
        margin: 0.5em 0 0.5em 1em;
    }

    a {
        color: $orange;
        text-decoration: underline;
    }

    .ez-toc-list li:before {
		content: none;
    }

    .youtube {
		position: relative;
		width: 100%;
		padding-top: 56.25%;
	}
	.youtube iframe{
		position: absolute;
		top: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

.pager_wrap {
	padding-top: 50px;
}

.pager {
	display: flex;
	box-lines:multiple;
	flex-wrap: nowrap;
	// align-items: center;
	justify-content: center;
	// flex-direction: row-reverse;

    @include media(tablet) {
        
    }

    .page-numbers {
    	color: #FFF;
    	background: $blue_l;
        @include font-size(2);
        display: inline-block;
        vertical-align: top;
        min-width: 50px;
        height: 50px;
        line-height: 50px;
        margin: 0 5px;
        text-align: center;


        &:hover,
        &.current {
        	background: $red;
        	color: #FFF;
            @include opacity(1);
        }

        &.prev,
        &.next {
        	// background: none;
            position: relative;
            transition: all 0.2s ease-out;
            text-indent: -9999px;

            &:after {
            	background: url(../img/common/arr_white.png) center center no-repeat;
            	background-size: auto 8px;
            	content: '';
            	display: block;
            	width: 50px;
            	height: 50px;
            	position: absolute;
            	top: 0;
            	left: 0;
            }

            &:hover {

            	&:after {
            		background-image: url(../img/common/arr_white.png);
            	}
            }
        }

        &.prev {
            transform: scale(-1, 1);
        }
    }
}

.wp-paging {
	
	a {
		// border-radius: 6px;
		// background: $gray;
		// color: #FFF;
		// @include font-size(2);
		// line-height: 1.5;
		// display: inline-block;
		// font-weight: bold;
		// min-width: 190px;
		// text-align: center;
		// padding-top: 16px;
		// padding-bottom: 16px;

		@include media(sp) {
			@include font-size(1.6);
			min-width: 100%;
		}

		// .btn__icon {
		// 	display: inline-block;
		// 	position: relative;
		// 	padding-right: 30px;

		// 	&:after {
		// 		background: url(../img/common/arr_white.png) 0 0 no-repeat;
		// 		background-size: 100% auto;
		// 		content: '';
		// 		display: block;
		// 		width: 23px;
		// 		height: 11px;
		// 		position: absolute;
		// 		top: 50%;
		// 		right: 0;
		// 		transform: translate(0, -50%);
		// 	}
		// }
		

		// @include media(sp) {
		// 	min-width: 80%;
		// }
	}


	&:after { clear: both; content: "."; display: block; height: 0; visibility: hidden; }

	.prev {
		float: left;

		@include media(sp) {
			width: 45%;
			// float: none;
			// margin-bottom: 20px;
		}

		a {
			// text-align: right;

			.btn__icon {
				padding-left: 30px;
				padding-right: 0;

				&:after {
					right: auto;
					left: 0;
					transform: scale(-1, 1) translate(0, -50%);
				}
			}
		}
	}

	.next {
		float: right;

		@include media(sp) {
			width: 45%;
		}
	}
}

.blog-footcv {
	position: relative;

	&:after {
		background: url(../img/common/illust_family) 0 0 no-repeat;
		background-size: 100% auto;
		content: '';
		display: block;
		width: 170px;
		height: 213px;
		position: absolute;
		top: 60px;
		right: 10px;

		@include media(1000) {
			top: -80px;
		}

		@include media(tablet) {
			width: 21vw;
			top: -11vw;
		}
	}
}

.blog-footcv__flex {

	@include media(1000) {
		display: block;

		> div:first-child {
			margin-bottom: 30px;
		}

		.grid2 {
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include media(tablet) {

		.grid2 {
			width: 100%;
		}
	}
}

.blog-footcv__btns {

	@include media(1000) {
		padding-left: 10%;
		padding-right: 10%;
	}

	.btn {
		margin-bottom: 10px;

		a {
			min-width: auto;
			width: 100%;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}